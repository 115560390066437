<template>
  <div class="login">
    <img class="login__logo" src="@/assets/img/logo_signin.svg" />
    <login-sns class="login__form" />
  <div class="login__footer">
    <p class="login__footer__supported">Được hỗ trợ bởi Blooms Inc.</p>
    <a class="login__footer__sendback" href="https://forms.gle/QxKiH9RnR7McZ4nU9" target="_blank">Gửi phản hồi đến chúng tôi</a>
  </div>
  </div>
</template>

<script>
import LoginSns from '@/components/login/sns'

export default {
  components: { LoginSns }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';
@import '@/assets/sass/color.scss';

.login {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 $main_padding * 2;
  background-image: url('../assets/img/login_background.svg');
  background-size: cover;
  text-align: center;
  &__logo {
    height: auto;
    width: 59.5vw;
    margin-top: 28vh;
  }
  &__form {
    margin-top: 35px;
  }
  &__footer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 38px;
    &__supported {
      margin: 38px 0 6px;
      font-size: 10px;
      color: $white_color;
      text-align: center;
    }
    &__sendback {
      margin: 0;
      font-size: 10px;
      font-weight: bold;
      color: $white_color;
      text-decoration: underline $white_color;
    }
  }
}
</style>
