<template>
  <div class="form">
    <v-btn class="form__btn--google" depressed :ripple="false" @click="login('google')">
      <img class="form__btn__icon" src="@/assets/img/google.svg" />
      <span class="form__btn__text--google">Tiếp tục với Google</span>
    </v-btn>
    <v-btn class="form__btn--facebook" depressed :ripple="false" @click="login('facebook')">
      <img class="form__btn__icon" src="@/assets/img/facebook.png" />
      <span  class="form__btn__text--facebook">Tiếp tục với Facebook</span>
    </v-btn>
    <!-- @todo 以下課題にて改修 -->
    <!-- @see https://github.com/nukumo/hanabi-main/issues/12 -->
    <processing v-if="isProcessing"></processing>
  </div>
</template>

<script>
// @todo 以下課題にて改修
// @see https://github.com/nukumo/hanabi-main/issues/12
import Processing from '@/components/common/processing'

export default {
  // @todo 以下課題にて改修
  // @see https://github.com/nukumo/hanabi-main/issues/12
  components: { Processing },
  data () {
    return {
      // @todo 以下課題にて改修
      // @see https://github.com/nukumo/hanabi-main/issues/12
      // 処理中かどうか
      isProcessing: false
    }
  },
  methods: {
    /**
     * SNSによるログイン
     * @param {String} sns SNS認証のプロバイダー名 google, facebook
     */
    login (sns) {
      this.$store.dispatch('auth/signin', sns)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';
@import '@/assets/sass/color.scss';

.form {
  width: 100%;
  max-width: 300px;
  &__btn {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    border-radius: 10px;
    &.v-btn:not(.v-btn--round) {
      height: 40px;
      padding: 0 8px;
      text-align: left;
      justify-content: left;
    }
    &--google {
      @extend .form__btn;
      &.theme--light.v-btn:not(.v-btn--flat) {
        background-color: $white_color;
      }
    }
    &--facebook {
      @extend .form__btn;
      &.theme--light.v-btn:not(.v-btn--flat) {
        background-color: $facebook_color;
      }
    }
    & ::v-deep .v-btn__content {
      min-width: 0;
      max-width: 100%;
      text-transform: none;
    }
    &__icon {
      height: 18px;
      width: 18px;
      margin-right: 24px;
      border-radius: 50%;
      vertical-align: bottom;
    }
    &__text {
      letter-spacing: 0.5px;
      font-size: 16px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &--google {
        @extend .form__btn__text;
        color: $google_text_color;
      }
      &--facebook {
        @extend .form__btn__text;
        color: $white_color;
      }
    }
  }
}
</style>
